const Language = require('./en')

class fr extends Language {

  constructor(metas, header, footer) {
    super(metas, header, footer)

    // Needs translating
    this.metas.title = "Bienvenue sur beam"
    this.metas.description = "Browse. Capture. Write. Publish – Meet the bright web"
    this.metas.ogTitle = "Meet the bright web – beam"
    this.metas.ogDescription = "Browse. Capture. Write. Publish – Coming soon to macOS. Join the beta now.",
    this.metas.twitterTitle = "Meet the bright web – beam"

    // Needs translating
    this.header.betaSignup = "Sign up for beta"
    this.header.betaSignupPlaceholder = "Enter your e-mail address..."
    this.header.betaSignupError = "We couldn't save your email, please try again"
    this.header.betaSignupSuccess = "Got it! We'll be in touch shortly"
    this.header.tryBeam = "Try Beam"
    this.header.downloadBeam = "Download Beam"

    // Needs translating (right value should be FR)
    this.translateFromArray(this.header.nav, "title", {
      "Overview": "Overview",
      "About": "About",
      "Changelog": "Changelog",
      "Sign in": "Sign in"
    })

    // Needs translating
    this.footer.jobs = "Jobs"
    this.footer.why = "Why beam?"
    this.footer.support = "Support"
    this.footer.privacyPolicy = "Privacy"
  }

  // Needs translating
  demo = {
    title: "Beneath your&nbsp;<br/><strong>browser</strong>…"
  }

  tabs = {
    journal: "Journal",
    allNotes: "Toutes les notes",
    note: "Note",
    bmail: "Bienvenue sur beam ! - bmail",
    beamTimes: "The beam Times - Actualités",
    youtube: "You On Kazoo! - YouTube"
  }

  journal = {
    today: {
      title: "Aujourd'hui",
      line1: "Support du markdown",
      line2: "Liens bidirectionnels",
      line3: "Chiffré de bout en bout",
      line4: "Organisé autour de votre journal",
    },
    yesterday: {
      title: "Hier",
      line1: "⌘K pour chercher sur le web et dans vos notes",
      line2: "⌘D pour basculer entre le web et vos notes",
      line3: "Appuyez sur ⌥ & cliquez pour capturer du contenu web"
    }
  }

  // Needs translating
  note = {
    title: "Note",
    publish: {
      error: "Error",
      publish: "Publish",
      publishing: "Publishing...",
      published: "Published!",
      unpublish: "",
      unpublishing: "",
      unpublished: "",
      url_copied: "URL copied"
    }
  }

  // Needs translating
  animation = {
    titles: [
      "A <br><strong class=\"in\">powerful note</strong> app…",
      "So you can <br><strong class=\"in\">capture</strong> the web…",
      "Make it <br><strong class=\"in\">your own</strong>…",
      "And <strong class=\"in\">share it</strong> <br>with the world",
      this.featureFlagsClient.isEnabled("download beta app")
        ? "<button class=\"beam-button large download-app\" data-from=\"prototype\">Download beam</button>"
        : "<strong class=\"in\">Sign up</strong> <br>for the beta now <span class=\"arrow\">-&gt;</span>"
    ]
  }
}

module.exports = fr;
